<template>
    <button type="button" class="btn border-0 rounded-circle"></button>
</template>

<script>
export default {
    name: "ControlButton"
}
</script>

<style scoped>
button {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-color: #AAABAB !important;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 70%;
}

button:hover {
    opacity: 0.8;
}

button:active {
    background-color: black;
    opacity: 0.6;
}

button:disabled {
    opacity: 0.2;
}

.control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
</style>


